<template>
  <layout page="activity-form">
    <page-title :icon="`lni lni-${id > 0 ? 'pencil' : 'plus'}`" :breadcrumb="breadcrumb">
      {{ id > 0 ? 'Editar Atividade' : 'Nova Atividade' }}
    </page-title>
    <div class="card">
      <div class="card-content">
        <div class="form">
          <error :error="error" />
          <div class="field">
            <label class="label" for="name">Título<asterisk /></label>
            <div class="control">
              <input v-model="name" class="input" type="text" name="name" id="name" placeholder="Titulo da atividade" ref="name" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="desc">Descrição</label>
            <markdown-editor v-model="desc" name="desc" id="desc" placeholder="Descrição da atividade" />
            <p class="help">Markdown syntax disponível, <a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">veja mais</a>.</p>
          </div>
          <div class="field">
            <label class="label" for="anex">Anexo</label>
            <div class="file" :class="{'has-name': hasFile}">
              <label class="file-label">
                <input
                  class="file-input"
                  type="file"
                  name="anex"
                  id="anex"
                  @change="upload"
                  ref="anex"
                  accept="application/pdf"
                >
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="lni lni-upload"></i>
                  </span>
                  <span class="file-label">
                    Escolher arquivo...
                  </span>
                </span>
                <span v-if="hasFile" class="file-name">
                  {{ anex }}
                </span>
                <span>
                  <button v-if="hasFile" class="button is-danger file-button" @click="removeFile">
                    <i class="lni lni-close" />
                  </button>
                </span>
              </label>
            </div>
            <p class="help">Tipos permitidos: PDF</p>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label" for="type">Tipo<asterisk /></label>
                <div class="select is-fullwidth">
                  <select v-model="type" name="type" id="type" ref="type">
                    <option
                      v-for="typeOpt in typeOptions"
                      :key="typeOpt.name"
                      :value="typeOpt.value || typeOpt.name"
                      :selected="typeOpt.value || typeOpt.name === type"
                    >
                      {{ typeOpt.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label" for="scoreRange">Possibilidade de nota</label>
                <div class="control">
                  <input v-model="scoreRange" class="input" type="text" scoreRange="scoreRange" id="scoreRange" placeholder="ex: 0 a 5 ou 5" ref="scoreRange" />
                </div>
                <p class="help is-danger">
                  Isto não é uma nota, é apenas uma informação.
                </p>
              </div>
            </div>
          </div>

          <div class="field is-grouped is-grouped-centered mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': enviando}" @click="send()">Salvar Atividade</button>
            </div>
            <div class="control">
              <router-link class="button is-link is-light" :to="`/admin/turmas/${idClass}/editar`">Cancelar</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import api from '@/api'
import SwalLoading from '@/helpers/SwalLoading'
import { DASHBOARD, CLASS_LIST, CLASS_FORM, ACTIVITY_FORM } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import Error from '@/components/ui/Error'
import Asterisk from '@/components/ui/Asterisk'
import MarkdownEditor from '@/components/MarkdownEditor'

export default {
  name: 'ActivityForm',
  components: {
    Layout,
    PageTitle,
    Error,
    Asterisk,
    MarkdownEditor
  },
  created () {
    this.idClass = this.$route.params.idClass
  },
  data () {
    return {
      id: 0,
      name: '',
      desc: '',
      type: 'normal',
      anex: '',
      scoreRange: '',
      idClass: 0,
      error: null,
      enviando: false,
      hasFile: false
    }
  },
  computed: {
    breadcrumb () {
      const form = { ...ACTIVITY_FORM, isActive: true }
      if (this.id > 0) ACTIVITY_FORM.text = 'Editar Atividade'

      return [
        DASHBOARD,
        CLASS_LIST,
        {
          ...CLASS_FORM,
          text: 'Editar Turma',
          path: `/admin/turmas/${this.idClass}/editar`
        },
        form
      ]
    },
    typeOptions () {
      return [
        {
          name: 'Atividade Comum',
          value: 'normal'
        },
        {
          name: 'Prova',
          value: 'exam'
        }
      ]
    }
  },
  methods: {
    upload () {
      this.anex = this.$refs.anex.value
      this.hasFile = this.anex !== ''
    },
    removeFile () {

    },
    send () {
      if (this.name === '') {
        return this.fieldInvalid('name', 'Nome é obrigátorio')
      }
      this.error = null

      // Fazendo o upload
      SwalLoading.fire()
      const formData = new FormData()
      formData.append('anex', this.$refs.anex.files[0])
      formData.append('name', this.name)
      formData.append('desc', this.desc)
      formData.append('type', this.type)
      formData.append('scoreRange', this.scoreRange)
      if (this.id === 0) {
        api.post(`/activities/class/${this.idClass}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.status === 201) {
            this.$router.push(`/admin/turmas/${this.idClass}/editar#atividades`)
          } else {
            SwalLoading.close()
            this.enviando = false
            this.error = 'Não foi possível criar a Atividade'
          }
        }).catch(err => {
          SwalLoading.close()
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      } else {
      }
    },
    fieldInvalid (ref, message) {
      this.error = message
      this.$refs[ref].focus()
      this.enviando = false
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-form {
  .form {
    .file {
      .file-name {
        border-radius: 0;
      }
      .file-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
</style>
